import $ from 'jquery';
import prestashop from 'prestashop';

$(function () {

  if ($('#js-map').length === 0) {
    return;
  }

  let key = $('input[name="gmaps-api-key"]').val();

  $('body').append(`<script async defer src="https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap"></script>`);
});

window.initMap = function () {
  const center = new google.maps.LatLng(
    parseFloat($('input[name="gmaps-lat"]').val()),
    parseFloat($('input[name="gmaps-lng"]').val())
  );

  const map = new google.maps.Map(document.getElementById('js-map'), {
    zoom: 14,
    center: center,
  });

  const marker = new google.maps.Marker({
    position: center,
    map: map
  });
}
