import $ from 'jquery';

$(() => {
  const inputs = {
    width:  '[data-ppbs-width]',
    height: '[data-ppbs-height]',
    depth:  '[data-ppbs-depth]',
  };

  $(Object.values(inputs).join(',')).on('change keyup', () => {
    compute(inputs);
  });

  const DOMobserver = new MutationObserver(() => {
    if (document.getElementById('ppbs_widget')) {
      DOMobserver.disconnect();
      updateSurface();
    }

    $(`[data-dimension_name="height"], [data-dimension_name="width"]`).on('change', () => {
      updateSurface();
    });
  });

  if ($('.product-actions').length > 0) {
    DOMobserver.observe($('.product-actions').get(0), { childList: true, subtree: true });
  }
});

function updateSurface() {
  let values = [];

  ['width', 'height'].forEach((input) => {
    var $input = $(`[data-dimension_name="${input}"]`);

    if ($input.get(0).tagName === 'SELECT') {
      values.push($input.find(':selected').text());
    } else {
      values.push($input.val());
    }
  });

  $('#ppbs-surface-value').text(
    values.reduce((prev, curr) => prev * curr ).toFixed(2)
  );
}

function parseStringToFloat(val) {
  if (isNaN(parseFloat(val, 10)))
    return 0;

  return parseFloat(val, 10);
}

function compute(inputs) {
  let depth = parseStringToFloat($(inputs['depth']).val()) * 2 + 1;

  ['width', 'height'].forEach((input) => {
    var $input = $(`[data-dimension_name="${input}"]`);
    var realValue = parseStringToFloat($(inputs[input]).val()) + depth;

    if($input.is('select')) {
      let foundValue;

      $input.find('option').each((i, el) => {
        var val = $(el).text();

        if (val < realValue || foundValue) {
          return;
        }

        foundValue = val;
        $input.val($(el).val());
      });
    } else {
      $input.val(realValue);
    }

    $input.get(0).dispatchEvent(new Event('change', { bubbles: true }));
  });
}
