import $ from 'jquery';
import prestashop from 'prestashop';

$(() => {
  createProductSpin();

  $('.js-select-model option[value="1"]').hide();

  $('.js-select-category').change(() => {
    $('.js-select-model option[value="1"]').hide();

    let category_selected = $('.js-select-category option:selected').val();
    $(`.spareparts_product_${category_selected}`).show();
  });

  $('.js-select-model').change(() => {
    window.location.href = $('.js-select-model option:selected').data('link');
  });

  function createProductSpin() {
    const $quantityInput = $('.js-quantity-wanted-spare-parts');

    $quantityInput.TouchSpin({
      buttondown_class: 'btn btn-outline-dark js-touchspin',
      buttonup_class: 'btn btn-outline-dark js-touchspin',
      min: parseInt($quantityInput.attr('min'), 10),
      max: Infinity
    });

    $quantityInput.closest('.input-wrapper').addClass('touchspin-loaded');
  }

  $('[data-button-action="spare-parts-add-to-cart"]').on('click', (e) => {
    e.preventDefault();
    e.currentTarget.disabled = 'disabled'

    let $form = $(event.target).closest('form');
    let query = $form.serialize() + '&add=1&action=update';
    let actionURL = $form.attr('action');

    let defaults = {
      token: $form.find('[name="token"]').val(),
      id_product: $form.find('[name="id_product"]').val(),
      id_customization: $form.find('[name="id_customization"]').val(),
      add: 1,
      action: 'update'
    }

    let xhrs = [];

    $('.js-quantity-wanted-spare-parts')
      .filter((i, el) => parseInt(el.value) > 0)
      .each((i, el) => {
        let $group = $(el).closest('.js-spare-part-form-item').find('input[type="hidden"]');
        let payload = Object.assign({qty: el.value}, defaults);
        payload[$group.attr('name')] = $group.val();

        let xhr = $.post(actionURL, payload, null, 'json');
        xhrs.push(xhr);
      })
    ;

    $.when(...xhrs).then((resp) => {
      window.location = actionURL + '?action=show';
    }).fail((resp) => {
      window.location = actionURL + '?action=show';
    });

  });
});
