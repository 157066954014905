import prestashop from 'prestashop';
import $ from 'jquery';
import Swiper from 'swiper';

prestashop.blockcart = prestashop.blockcart || {};

prestashop.blockcart.showModal = (html) => {

  function getBlockCartModal() {
    return $('#blockcart-modal');
  }

  let $blockCartModal = getBlockCartModal();
  if ($blockCartModal.length){
    $blockCartModal.remove();
  }

  $('body').append(html);

  $blockCartModal = getBlockCartModal();
  $blockCartModal.on('shown.bs.modal', () => {
    $blockCartModal.get(0).querySelectorAll('.js-product-slider').forEach((el) => {
      let navigation = {
        prevEl: el.parentNode.querySelector('.swiper-button-prev'),
        nextEl: el.parentNode.querySelector('.swiper-button-next'),
      };

      var config = {
        speed: 750,
        slidesPerGroup: 2,
        slidesPerView: 2,
        loop: true,
        breakpointsInverse: true,
        breakpoints: {
          768: {
            slidesPerGroup: 2,
            slidesPerView: 2,
            navigation: navigation,
          },
          992: {
            slidesPerGroup: 3,
            slidesPerView: 3,
            navigation: navigation,
          }
        }
      };

      new Swiper(el, config);
    });
  });

  $blockCartModal.modal('show');
};
