import $ from 'jquery';

export default class TopMenu  {
  init () {
    $('.header-nav .nav-item.dropdown').on('mouseover', function () {
      $('.menu-overlay').addClass('menu-overlay--show');
    });

    $('.header-nav .nav-item.dropdown').on('mouseout', function () {
      if ('ontouchstart' in window) {
        return;
      }

      $('.menu-overlay').removeClass('menu-overlay--show');
      $('#main-navigation').removeClass('show');
    });

    $('.header-nav .nav-link.dropdown-toggle').off('click').on('click', function (event) {
      event.preventDefault();

      if ($(this).attr('href') !== '#') {
        window.location = $(this).attr('href');
      }
    });

    $('.menu-overlay').on('click', () => {
      $('#main-navigation').removeClass('show');
      $('.menu-overlay').removeClass('menu-overlay--show');

      setTimeout(() => {
        $('.header-nav .nav-item.dropdown').removeClass('open');
      }, 250);
    });

    $('#main-navigation').on('show.bs.collapse', function () {
      $('.menu-overlay').addClass('menu-overlay--show');
    });

    $('.header-nav .nav-item.dropdown .nav-link').on('click', (event) => {
      if ('ontouchstart' in window) {
        event.preventDefault();
      }

      let wasOpen = $(event.currentTarget).parent().hasClass('open');

      $('.header-nav .nav-item.dropdown').removeClass('open');

      if (!wasOpen) {
        $(event.currentTarget).parent().addClass('open');
      }
    })

  }
}
