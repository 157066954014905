import $ from 'jquery';
import prestashop from 'prestashop';
import noUiSlider from 'nouislider';

$(document).ready(() => {

  $('.js-read-more').on('click', (e) => {
    $(e.currentTarget).parent().prev('.js-read-content').addClass('open');
    e.preventDefault();
    return false;
  });

  const initState = {
    rendered_facets: $('#search_filters').clone(),

    rendered_products_top: $('#js-product-list-top').clone(),
    rendered_products: $('#js-product-list').clone(),
    rendered_products_bottom: $('#js-product-list-bottom').clone(),
  }

  setupRangeInput();

  const parseSearchUrl = function (event) {
    if (event.target.dataset.searchUrl !== undefined) {
      return event.target.dataset.searchUrl;
    }

    if ($(event.target).parent()[0].dataset.searchUrl === undefined) {
      throw new Error('Can not parse search URL');
    }

    return $(event.target).parent()[0].dataset.searchUrl;
  };

  $('body').on('change', '#search_filters input[data-search-url]', function (event) {
    prestashop.emit('updateFacets', parseSearchUrl(event));
  });

  $('body').on('change', '#search_filters select', function (event) {
    prestashop.emit('updateFacets', $(event.target).val());
  });

  $('body').on('click', '.js-search-link', function (event) {
    event.preventDefault();
    prestashop.emit('updateFacets', $(event.target).closest('a').get(0).href);
  });

  $('body').on('change', '.js-search-select', function (event) {
    event.preventDefault();
    prestashop.emit('updateFacets', $(event.target).val());
  });

  prestashop.on('updateProductList', (data) => {
    updateProductListDOM(data);
    setupRangeInput();

    window.scrollTo(0, $('#main').offset().top - 50);
  });

  window.onpopstate = function(event) {
    updateProductListDOM(event.state ? event.state : initState);
    setupRangeInput();
  };
});

function updateProductListDOM (data) {
  $('#search_filters').replaceWith(data.rendered_facets);

  $('#js-product-list-top').replaceWith(data.rendered_products_top);
  $('#js-product-list').replaceWith(data.rendered_products);
  $('#js-product-list-bottom').replaceWith(data.rendered_products_bottom);
}

function setupRangeInput () {

  let sliders = document.getElementsByClassName('js-facet-range-slider');

  for (var i = 0; i < sliders.length; i++) {
    let slider = sliders[i];

    noUiSlider.create(slider, {
      step: 1,
      connect: [false, true, false],
      start: [
        parseInt($(slider).data('from')),
        parseInt($(slider).data('to'))
      ],
      range: {
        min: [parseInt($(slider).data('min'))],
        max: [parseInt($(slider).data('max'))]
      },
      format: {
        from: (value) => fixFloat(value),
        to: (value)   => fixFloat(value)
      }
    });

    slider.noUiSlider.on('update', onSliderUpdate);
    slider.noUiSlider.on('change', onSliderChange);

    $(slider).animate({ opacity: 1 }, 100);
  }

}

function onSliderChange (values) {
  prestashop.emit('updateFacets', processFacetUrl($(this.target), values));
}

function onSliderUpdate (values) {
  $(this.target).parent().find('.js-facet-range-slider-from').text(fixFloat(values[0]));
  $(this.target).parent().find('.js-facet-range-slider-to').text(fixFloat(values[1]));
}

function processFacetUrl ($target, values) {
  let url = $target.data('url');

  let filter = [
    $target.data('label'),
    $target.data('symbol')
  ].join('-');

  let query = encodeURI([
    filter,
    ...values,
  ].join('-'));

  if (!/\?q=/g.test(url)) {
    return `${url}?q=${query}`;
  }

  if (url.includes(encodeURI(filter))) {
    return url.replace(/(.+-)(\d+[\.\d]*-\d+[\.\d]*)/g, (match, $1, $2) => $1 + values.join('-'));
  }

  return `${url}/${query}`;
}

function fixFloat(val) {
  return parseFloat(val).toFixed(2);
}
