import 'babel-polyfill';

import 'bootstrap';
import 'bootstrap-touchspin';

import './responsive';
import './checkout';
import './customer';
import './contact';
import './listing';
import './product';
import './cart';
import './spare-parts';
import './jardin-ambiance';
import './home';

import './components/ppbs-helper';

import Swiper from 'swiper';

import Form from './components/form';
import TopMenu from './components/top-menu';

import prestashop from 'prestashop';
import EventEmitter from 'events';

import { linkDecrypt } from './components/links';

import './components/block-cart';

if ('NodeList' in window && !NodeList.prototype.forEach) {
  console.info('polyfill for IE11');
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

for (var i in EventEmitter.prototype) {
  prestashop[i] = EventEmitter.prototype[i];
}

$(document).ready(() => {
  const form = new Form();
  form.init();

  const topMenu = new TopMenu();
  topMenu.init();

  linkDecrypt();

  document.querySelectorAll('.js-product-slider').forEach((el) => {
    let navigation = {
      prevEl: el.parentNode.querySelector('.swiper-button-prev'),
      nextEl: el.parentNode.querySelector('.swiper-button-next'),
    };

    var config = {
      speed: 750,
      slidesPerGroup: 2,
      slidesPerView: 2,
      loop: true,
      breakpointsInverse: true,
      breakpoints: {
        768: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          navigation: navigation,
        },
        992: {
          slidesPerGroup: 4,
          slidesPerView: 4,
          navigation: navigation,
        }
      }
    };

    if (el.dataset.autoplay) {
      config.autoplay = { delay: 5000 };
    }

    new Swiper(el, config);
  });
});

const DOMobserver = new MutationObserver(() => {
  linkDecrypt();
});
DOMobserver.observe(document.body, { childList: true, subtree: true });
