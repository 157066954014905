import $ from 'jquery';
import prestashop from 'prestashop';

import Swiper from 'swiper';

$(document).ready(function () {
  createProductSpin();

  var modalSlider;

  const mainSlider = new Swiper('.js-product-images-main', {
    slidesPerView: 1,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    thumbs: {
      swiper: {
        el: '.js-product-images-nav',
        slidesPerView: 4,
      }
    },
  });

  prestashop.on('updatedProduct', function (event) {
    if (event && event.product_minimal_quantity) {
      const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      const quantityInputSelector = '.js-quantity-wanted';
      let quantityInput = $(quantityInputSelector);

      quantityInput.trigger('touchspin.updatesettings', {min: minimalProductQuantity});
    }

    $($('.tabs .nav-link.active').attr('href')).addClass('active').removeClass('fade');
  });

  $(document).on('click', '.js-zoom-modal-link', function (e) {
    e.preventDefault();
     // recupération de la position de l'image sélectionnée
    mainSlider.activeIndex = $('.swiper-wrapper .swiper-slide-active img').data('position');

    $('#zoom-modal').modal('show');
  });

  $('#zoom-modal').on('shown.bs.modal', function () {
    modalSlider = new Swiper('.js-product-images-modal', {
      initialSlide: mainSlider.activeIndex,
      slidesPerView: 1,
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  });

  $('#zoom-modal').on('hidden.bs.modal', function () {
    modalSlider.destroy();
  });

  function createProductSpin() {
    const $quantityInput = $('.js-quantity-wanted');

    $quantityInput.TouchSpin({
      buttondown_class: 'btn btn-outline-dark js-touchspin',
      buttonup_class: 'btn btn-outline-dark js-touchspin',
      min: parseInt($quantityInput.attr('min'), 10),
      max: Infinity
    });

    $quantityInput.closest('.input-wrapper').addClass('touchspin-loaded');

    $('body').on('change keyup', '.js-quantity-wanted', (e) => {

      $(e.currentTarget).trigger('touchspin.stopspin');

      prestashop.emit('updateProduct', {
        eventType: 'updatedProductQuantity',
        event: e
      });
    });
  }
});
