import $ from 'jquery';
import Swiper from 'swiper';

$(() => {

  if (document.getElementById('home-carousel')) {
    let dataset = document.getElementById('home-carousel').dataset;

    const HomeSlider = new Swiper('#home-carousel', {
      speed: 500,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: (dataset.wrap === "true"),
      autoplay: {
        delay: dataset.interval,
      },
    });

    if (dataset.pause === "hover") {
      HomeSlider.el.addEventListener("mouseenter", function( event ) {
        HomeSlider.autoplay.stop();
      }, false);

      HomeSlider.el.addEventListener("mouseleave", function( event ) {
        HomeSlider.autoplay.start();
      }, false);
    }
  }

});
