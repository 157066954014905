import $ from 'jquery';
import prestashop from 'prestashop';

$(() => {
  createProductSpin();

  function createProductSpin() {
    const $quantityInput = $('.js-quantity-wanted-jardin-ambiance');

    $quantityInput.TouchSpin({
      buttondown_class: 'btn btn-outline-dark js-touchspin',
      buttonup_class: 'btn btn-outline-dark js-touchspin',
      min: parseInt($quantityInput.attr('min'), 10),
      max: Infinity
    });

    $quantityInput.closest('.input-wrapper').addClass('touchspin-loaded');
  }

  $('[data-button-action="jardin-ambiance-add-to-cart"]').on('click', (e) => {
    e.preventDefault();
    e.currentTarget.disabled = 'disabled';

    let $form = $(event.target).closest('form');
    let query = $form.serialize() + '&add=1&action=update';
    let actionURL = $form.attr('action');

    let defaults = {
      token: $form.find('[name="token"]').val(),
      add: 1,
      action: 'update'
    }

    let xhrs = [];

    $('.js-quantity-wanted-jardin-ambiance')
      .filter((i, el) => parseInt(el.value) > 0)
      .each((i, el) => {
        let payload = Object.assign({
          id_product: $(el).closest('.js-spare-part-form-item').find('[name="id_product"]').val(),
          qty: el.value
        }, defaults);

        let xhr = $.post(actionURL, payload, null, 'json');
        xhrs.push(xhr);
      })
    ;

    $.when(...xhrs).then((resp) => {
      window.location = actionURL + '?action=show';
    }).fail((resp) => {
      window.location = actionURL + '?action=show';
    });

  });

  $('[data-toggle="popover"]').popover({
    placement: 'top',
    trigger: 'hover',
    title: function () {
      return $(this).data('product-name');
    },
    html: true,
    content: function () {
      var element = '#popover-product-'+$(this).data('product-id');

      return $(element).html();
    },
  });

  refreshTotal();

  $('.js-touchspin').on('click', () => refreshTotal());
  $('.js-quantity-wanted-jardin-ambiance').on('change', () => refreshTotal());
});

function refreshTotal() {
  let data = {
    products: [],
  };

  $('.js-quantity-wanted-jardin-ambiance')
    .filter((i, el) => parseInt(el.value) > 0)
    .each((i, el) => {
      data.products.push({
        quantity: el.value,
        price: $(el).data('price'),
      });
    })
  ;

  $.post($('.js-quantity-wanted-jardin-ambiance').data('ajax-url'), data).then(function (response) {
    $('.js-total-part-jardin').html(response.total);
  });
}
