import $ from 'jquery';

export default class Form {

  init () {
    const $selects = $('.form-select-outlined > select');

    this.parentFocus();
    this.togglePasswordVisibility();

    $selects.each((i, el) => this.selectPlaceholder(el));
    $selects.on('change', (e) => this.selectPlaceholder(e.currentTarget));

    $(".custom-file-input").on('change', (e) => {
      let fileName = $(e.currentTarget).val();
      let $label = $(e.currentTarget).next('.custom-file-label').find('.filename');

      if ($(e.currentTarget).val() !== '') {
        $label.removeClass('filename--placeholder');
      };

      $label.html(fileName.replace('C:\\fakepath\\', ''));
    });

    $('.js-date-select select').wrap('<div class="form-group form-group-outlined col-4"></div>');
  }

  selectPlaceholder (target) {
    let $parent = $(target).parent();
    $parent.toggleClass('-is-placehoder', $(target).val() === '');
  }

  parentFocus() {
    $('.js-child-focus').focus(function () {
      $(this).closest('.js-parent-focus').addClass('focus');
    });
    $('.js-child-focus').focusout(function () {
      $(this).closest('.js-parent-focus').removeClass('focus');
    });
  }

  togglePasswordVisibility() {
    $('button[data-action="show-password"]').on('click', function () {
      var elm = $(this).closest('.input-group').children('input.js-visible-password');
      if (elm.attr('type') === 'password') {
        elm.attr('type', 'text');
        $(this).find('i').addClass('fa-eye-slash').removeClass('fa-eye');
      } else {
        elm.attr('type', 'password');
        $(this).find('i').addClass('fa-eye').removeClass('fa-eye-slash');
      }
    });
  }
}
